import { useRoute } from 'nuxt/app'

const useChartUtils = () => {
  const route = useRoute()

  const makeUrlDir = (type) => {
    const types = {
      company: 'companies',
      country: 'countries',
      region: 'regions',
      location: 'locations',
      facility: 'facilities',
      mine: 'mines',
      plant: 'plants',
      product: 'products',
      feedstock: 'feedstocks',
      'ore-type': 'ore-type',
    }
    const dirsArr = route.path.split('/')
    const baseUrl = dirsArr.slice(0, dirsArr.length - 3).join('/')
    const pluralType = types[type]

    return `${baseUrl}/${pluralType}`
  }

  const sortDataByProduction = (data) => {
    return data.sort((a, b) => b.totalValue - a.totalValue)
  }

  const sortDataByGroup = (data) => {
    let newData = []

    const itemsWithValues = data.filter((item) => item.totalValue > 0)
    const itemsWithoutValues = data.filter((item) => item.totalValue === 0)

    itemsWithValues.sort((a, b) => a.name.localeCompare(b.name))
    itemsWithoutValues.sort((a, b) => a.name.localeCompare(b.name))

    newData = [...itemsWithValues, ...itemsWithoutValues]

    return newData
  }

  return { makeUrlDir, sortDataByProduction, sortDataByGroup }
}

export default useChartUtils
